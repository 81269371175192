<template>
    <div class="group-school">
        <div>
            <div class="shoolName">{{ schoolName }}</div>
            <div>
                <el-form
                    ref="form"
                    label-width="80px"
                    :inline="true"
                    style="margin-top: 13px"
                >
                    <el-form-item label="">
                        <el-cascader
                            placeholder="所属区域"
                            ref="cityCascader"
                            v-model="form.regionArr"
                            :options="cityList"
                            clearable
                            :props="cascaderProps"
                            @change="handleChange"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            v-model="form.schoolStage"
                            placeholder="学段"
                            clearable
                            @change="handleChange"
                        >
                            <el-option
                                v-for="item in stageList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div>
            <div
                v-if="schoolData.length > 0"
                class="gradMain"
            >
                <div
                    class="shoolCard"
                    v-for="item in this.schoolData"
                    :key="item.schoolName"
                >
                    <div class="shoolLogo"><img :src="item.logo" /></div>
                    <div>{{ item.schoolName }}</div>
                    <div
                        class="btn"
                        @click="goToBtn(item)"
                    >
                        <div>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="schoolDataSchool.length > 0"
                class="gradMain"
            >
                <div
                    class="shoolCard"
                    v-for="item in this.schoolDataSchool"
                    :key="item.schoolName"
                >
                    <div class="shoolLogo"><img :src="item.logo" /></div>
                    <div>{{ item.schoolName }}</div>
                    <div
                        class="btn"
                        @click="goToBtn(item)"
                    >
                        <div>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {CommonModel} from "@/models/Common.js";
import {arrSortByName} from "@/libs/utils";
export default {
    data() {
        const _this = this;
        return {
            cityListCode: [], // 省code
            municipalCode: [],  //市code
            schoolData: [],
            schoolDataSchool: [],
            carouselData: [],
            schoolName: "",
            stageList: [],
            form: {
                regionArr: [], //所属区域
                provinceCode: "", //省code
                cityCode: "", //市code
                areaCode: "", //区code
                schoolStage: "", //学段
            },
            cityList: [],
            cascaderProps: {
                value: "value",
                label: "label",
                checkStrictly: true,
                leaf: "leaf",
                lazy: true,
                lazyLoad(node, resolve) {
                    const {level} = node;
                    if (!node.value) {
                        resolve();
                        return;
                    }
                    _this.getCityListNode(node.value).then((res) => {
                        if (node.children && node.children.length > 0) {
                            resolve();
                        } else {
                            let childNode = res.map((item) => {
                                return {
                                    label: item.areaName,
                                    value: item.areaCode.toString(),
                                    level: item.level,
                                    parentCode: item.parentCode,
                                    id: item.id,
                                    children: [],
                                    leaf: level >= 2,
                                };
                            });

                            let data = _this.filterCityData(childNode);
                            resolve(data);
                        }
                    });
                },
            },
        };
    },
    async created() {
        await this.cutData();
        this.getStageListData();
        this.getCityList(0);
    },
    methods: {
        handleChange(node) {
            // 调用接口
            this.cutData();
        },
        filterCityData(data){
            let listData = data
            let cityCodeArr = this.municipalCode
            let newData = []

            if(data[0].level == 2){
                cityCodeArr.forEach(item => {
                    listData.forEach(item2 => {
                        if(item2.value == item){
                            newData.push(item2)
                        }
                    })
                })
            } else {
                newData = listData
            }
            return newData
        },
        // 获取并处理学校列表数据
        cutData() {
            return new Promise((resolve) => {
                this.schoolData = [];
                this._fet("/auth/sc/getDetailByProjectMark", {
                    productId: "cloud_campus",
                    projectMark: this.$store.state.projectMark,
                }).then((res) => {
                    this.cityListCode = [];
                    this.municipalCode = [];
                    this.schoolName = res.data.data.schoolName;
                    this._fet("/school/schoolUser/getSchoolListByGroupId", {
                        groupId: res.data.data.id,
                        ...this.form,
                    }).then((r) => {
                        let data = r.data.data;
                        data.forEach((item) => {
                            let code = item.provinceCode;
                            if (code && !this.cityListCode.includes(code)) {
                                this.cityListCode.push(code);
                            }
                        });
                        // 拿取所有省份code
                        data.forEach((item) => {
                            let code = item.cityCode;
                            if (code && !this.municipalCode.includes(code)) {
                                this.municipalCode.push(code);
                            }
                        });
                        
                        this.schoolData = data.filter(
                            (item) => item.isGroup == "1",
                        );
                        this.schoolDataSchool = data.filter(
                            (item) => item.isGroup == "2",
                        );

                        resolve(this.cityListCode);
                        // this.schoolData = r.data.data;
                    });
                });
            });
        },
        // 获取学段
        getStageListData() {
            this.stageList = [];
            const commonModel = new CommonModel();
            commonModel.getDictList("schoolStage ").then((res) => {
                if (res.data && res.data.code == "200") {
                    let data = res.data.data;
                    data.forEach((item) => {
                        this.stageList.push({
                            label: item.name,
                            value: item.id,
                        });
                    });
                }
            });
        },
        // 单点登录学校端
        goToBtn(data) {
            // 学校
            if (data.isGroup === "2") {
                this._fet("/auth/sc/autoLogin", {schoolId: data.id}).then(
                    (res) => {
                        this.handleRes(res, () => {
                            if (data.projectMark) {
                                let schoolUrl =
                                    "/?projectMark=" +
                                    data.projectMark +
                                    "&token=" +
                                    res.data.data.token;
                                let promise = new Promise((reslove) => {
                                    reslove(schoolUrl);
                                });
                                promise.then((res) => {
                                    const _window = window.open();
                                    if (_window) {
                                        _window.location.href = res;
                                    } else {
                                        const a = document.createElement("a");
                                        a.href = res;
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    }
                                });
                            }
                        });
                    },
                );
            }
            // 教育局
            if (data.isGroup === "1") {
                let eduBaseUrl = `${location.protocol}//${location.hostname}:${location.port}`;
                this._fet("/auth/sc/autoLoginBureau", {schoolId: data.id}).then(
                    (res) => {
                        this.handleRes(res, () => {
                            if (data.projectMark) {
                                let schoolUrl =
                                    eduBaseUrl +
                                    "/education/?projectMark=" +
                                    data.projectMark +
                                    "&token=" +
                                    res.data.data.token +
                                    "#/";
                                let promise = new Promise((resolve) => {
                                    resolve(schoolUrl);
                                });
                                promise.then((res) => {
                                    const _window = window.open();
                                    if (_window) {
                                        _window.location.href = res;
                                    } else {
                                        const a = document.createElement("a");
                                        a.href = res;
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    }
                                });
                            }
                        });
                    },
                );
            }
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 获取城市数据
        getCityList(parentCode) {
            let _this = this;
            return new Promise(async (resolve, reject) => {
                const commonModel = new CommonModel();

                await commonModel
                    .getAreaDropDown({
                        parentCode: parentCode,
                    })
                    .then((res) => {
                        let resArr = arrSortByName(res.data.data, "areaName");
                        if (!parentCode) {
                            let cityList = resArr.map((item) => {
                                return {
                                    label: item.areaName,
                                    value: item.areaCode.toString(),
                                    level: item.level,
                                    parentCode: item.parentCode,
                                    id: item.id,
                                    children: [],
                                    leaf: false,
                                };
                            });

                            this.cityListCode.forEach((item) => {
                                cityList.forEach((city) => {
                                    if (city.value == item) {
                                        _this.cityList.push(city);
                                    }
                                });
                            });
                        }
                    });
            });
        },
        // 获取城市数据
        getCityListNode(parentCode) {
            let _this = this;
            return new Promise(async (resolve, reject) => {
                const commonModel = new CommonModel();

                await commonModel
                    .getAreaDropDown({
                        parentCode: parentCode,
                    })
                    .then((res) => {
                        let resArr = arrSortByName(res.data.data, "areaName");
                        resolve(resArr);
                    });
            });
        },
    },
    watch: {
        cityListCode: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue.length > 0) {
                }
            },
        },
        "form.regionArr": {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                let lenght = newValue.length;
                this.form.provinceCode = "";
                this.form.cityCode = "";
                this.form.areaCode = "";
                if (lenght == 1) {
                    this.form.provinceCode = newValue[0];
                }
                if (lenght == 2) {
                    this.form.provinceCode = newValue[0];
                    this.form.cityCode = newValue[1];
                }
                if (lenght == 3) {
                    this.form.provinceCode = newValue[0];
                    this.form.cityCode = newValue[1];
                    this.form.areaCode = newValue[2];
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.group-school {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    background-image: url("../assets/images/schoolBackgroud.png");
    & > div {
        box-sizing: border-box;
        // border: 1px solid white;
    }
    & > div:first-of-type {
        padding: 0 30px;
        height: 60px;
        line-height: 58px;
        flex-grow: 0;
        background-color: rgba($color: #fff, $alpha: 0.8);
        display: flex;
        justify-content: space-between;

        .shoolName {
            font-size: 20px;
            font-weight: bold;
        }
        ::v-deep {
            .el-input__inner {
                border-color: #f2f5f7;
            }
        }
    }
    & > div:nth-of-type(2) {
        overflow: auto;
        flex: 1;
        .gradMain {
            overflow: hidden;
            width: 1360px;
            margin: 5px auto;
            margin-bottom: 0px;
            padding-bottom: 30px;
            // border: 1px solid black;

            .shoolCard {
                float: left;
                width: 300px;
                box-sizing: border-box;
                overflow: hidden;
                position: relative;
                transition: all 0.4s;
                background: white;
                box-sizing: border-box;
                padding: 0px 20px;
                margin: 20px;
                border: 1px solid white;
                border-radius: 16px;
                height: 95px;
                display: flex;
                align-items: center;
                .btn {
                    position: absolute;
                    right: -90px;
                    background-image: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0.4),
                        rgba(255, 255, 255, 1)
                    );
                    border-radius: 0px 16px 16px 0px;
                    height: 95px;
                    width: 90px;
                    z-index: 10;
                    text-align: center;
                    color: white;
                    line-height: 35px;
                    cursor: pointer;
                    transition: all 0.1s;
                    & > div {
                        line-height: 35px;
                        width: 35px;
                        height: 35px;
                        margin: 28px auto;
                        border-radius: 50%;
                        background-color: royalblue;
                    }
                }
                .shoolLogo {
                    width: 60px;
                    height: 60px;
                    border: 1px solid white;
                    overflow: hidden;
                    border-radius: 4px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                & > div:nth-of-type(2) {
                    height: 60px;
                    flex: 1;
                    // border: 1px solid black;
                    overflow: hidden;
                    font-weight: bold;
                    line-height: 30px;
                    padding: 0px 10px;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    white-space: normal;
                }
                &:hover {
                    border: 1px dashed #4383fa;
                    .btn {
                        right: 0px;
                    }
                }
            }
            .shoolCard:nth-of-type(4n) {
                margin-right: 0px;
            }
            .shoolCard:hover {
                box-shadow: 0px 10px 25px 0px #b6b9bf;
            }
        }
        .gradMain:nth-of-type(2) {
            margin-top: 0px;
            margin-bottom: 30px;
        }
        .gradMainText {
            font-size: 25px;
            font-weight: bold;
            color: #333;
            padding-left: 100px;
            margin: 10px 0px;
        }
    }
}
</style>
