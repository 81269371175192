<template>
    <div class="login">
        <div class="login-content">
            <div class="login-page">Login page</div>
            <div>
                <div class="manager-title">
                    <span class="title">{{schoolName}}</span>
                    <img :src="managerTitle" alt="title">
                    <div class="manager-info">
                        <p>鑫考云校园平台</p>
                        <p>是按照《中国教育管理信息化标准》的要求</p>
                        <p>结合众多中小学在教育管理过程中的实际需求开发的一套教育教学管理软件。</p>
                    </div>
                </div>
                <div v-if="!inDingTalk" class="form-wrapper">
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login__form">
                        <div class="login-form-bc"></div>
                        <div class="login__form__title">{{ title || "研发三部单服务基础系统" }}</div>
                        <el-form-item prop="userName">
                            <el-input v-model="loginForm.userName" type="text" auto-complete="off" placeholder="账户"
                                size="medium">
                                <template #prepend>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
                                size="medium" @keyup.enter.native="handleLogin" show-password>
                                <template #prepend>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%"
                                size="medium" @keyup.enter.native="handleLogin()"><template #prepend>
                                </template>
                            </el-input>
                            <div class="login__form__code">
                                <img :src="codeUrl" class="login__form__code__img" @click="referhCode">
                            </div>
                        </el-form-item>
                            <el-button :style="{'margin-top': marginTop}" :loading="loading" size="medium" type="primary" class="xkButton" @click="handleLogin()">
                                <span v-if="!loading">登 录</span>
                                <span v-else>登 录 中...</span>
                            </el-button>
                    </el-form>
                </div>
            </div>
            <div class="foot-img">
                <img :src="footImg" alt="foot-img" />
            </div>
        </div>
        </div>
</template>

<script>
    import {
        title
    } from '@/libs/settings.js';
    import {
        serviceDownload
    } from '../services/index.js';
    import {
        setToken
    } from '@/libs/auth';
    import {
        LoginModel
    } from '@/models/Login.js';
    import { Encrypt } from '@/libs/secret';
    import { param2Obj } from '@/libs/utils';
    import { getCode } from "@/libs/dingtalk";
    import * as dd from 'dingtalk-jsapi'
    export default {
        name: 'Login',
        data() {
            return {
                title,
                schoolName:'',
                codeUrl: '',
                loginForm: {
                    userName: '',
                    password: '',
                    code: '',
                    plat: 'school',
                    projectMark: 'FQ',
                    paramDate: +new Date()
                },
                src: '',
                loginRules: {
                    code: [{
                        required: true,
                        trigger: 'blur',
                        message: '请输入验证码'
                    }],
                    userName: [{
                        required: true,
                        trigger: 'blur',
                        message: '请输入账户名'
                    }],
                    password: [{
                        required: true,
                        trigger: 'blur',
                        message: '请输入密码'
                    }]
                },
                loading: false,
                redirect: undefined,
                marginTop: '20px',
                inDingTalk: false
            }
        },
        created() {
            this.inDingTalk = dd.env.platform !== 'notInDingTalk'
            this.init()
            this.binEvent();
        },
        computed: {
            managerTitle () {
                return require('../assets/images/manager-title.png')
            },
            footImg () {
                return require('../assets/images/logo-foot-img.png')
            }
        },
        methods: {
            async init() {
                if (!this.inDingTalk) {
                    // HSSDSZX352  衡水市第四中学
                    // XSGLKFCS    宿舍管理开发测试
                    // window.location.search = '?projectMark=HSSDSZX352'
                    const param = param2Obj(location.search)
                    if (!param.projectMark) {
                        return this.$message.error('缺少项目标识')
                    }
                    this.$store.state.projectMark = param.projectMark || ''
                    this.loginForm.projectMark = param.projectMark
                    this.codeUrl = await serviceDownload(this.loginForm.paramDate);
                    console.log(param.projectMark,'this.codeUrlthis.codeUrl')
                    if (process.env.NODE_ENV === 'development') {
                        this.loginForm.userName = 'admin'
                        this.loginForm.password = 'Yxy@2022'
                    }
                    this.getInfo()
                } else {
                    // 钉钉登录
                    this.dingLogin();
                }
            },
            getInfo(){
                this._fet('/auth/sc/getDetailByProjectMark', {
                    productId:'cloud_campus',
                    projectMark:this.loginForm.projectMark
            }).then((res) =>{
                    // console.log(res.data.data.schoolName,'获取项目信息');
                    this.schoolName=res.data.data.schoolName
                })
            },
            // 刷新验证码图片
            async referhCode() {
                this.loginForm.code = ''
                this.loginForm.paramDate = +new Date()
                this.codeUrl = await serviceDownload(this.loginForm.paramDate)
            },
            dingLogin() {
                getCode(code => {
                    this._fet('/auth/sc/dingLoginHszx', {
                        code: code,
                        projectMark: param2Obj(location.search).projectMark || '',
                        mode: 'pc'
                    }).then((res) => {
                        if (res.data.code === '200') {
                            setToken(res.data.data.token);
                            this.$router.push({
                                // path: '/groupSchool',
                                name: 'GroupSchool'
                            });
                            this.loading = false
                        } else {
                            this.referhCode()
                            this.$message.error(res.data.msg)
                            this.loading = false
                        }
                    })
                })
            },
            // 登录
            handleLogin() {
                const _this = this;
                this.$refs.loginForm.validate((valid) => {
                  if (!valid) return false
                   let obj = JSON.parse(JSON.stringify(this.loginForm))
                   obj.password = Encrypt(obj.password)
                   this.loading = true
                   const loginModel = new LoginModel();
                   loginModel.login(obj).then((res) => {
                       if (res.data.code === '200') {
                           setToken(res.data.data.token);
                           _this.$router.push({
                                // path: '/groupSchool'
                               name: "GroupSchool"
                            });
                           this.loading = false
                       } else {
                         this.referhCode()
                         this.$message.error(res.data.msg)
                           this.loading = false
                       }
                   });
                })
            },
            binEvent () {
                let h = document.documentElement.clientHeight;
                this.initH(h)
                window.addEventListener('resize', this.resize, false)
            },
            resize () {
                let h = document.documentElement.clientHeight;
                this.initH (h)
            },
            initH (h) {
                if (h < 944) {
                    this.marginTop = '20px'
                }

                if (h < 813) {
                    this.marginTop = '-50px'
                }

                if (h < 802) {
                    this.marginTop = '-16px'
                }

              if (h < 750) {
                this.marginTop = '-30px'
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media (max-width: 1024px) and (min-width: 769px){
        .login .login-content .form-wrapper {
            width: 90%;
            top: 720px;
            z-index: 1;
            left: 5%;
        }
    }

    @media (max-width: 768px){
        .login .login-content {
            width: 100% !important;
            margin-top: 0 !important;
            margin-left: 0 !important;
        }
        .login .login-content .form-wrapper {
            width: 90%;
            top: 490px;
            z-index: 1;
            left: 5%;
        }
    }
    .login {
        width: 100vw;
        height: 100vh;
        background-image: url(~@/assets/images/bg.png);
        background-size: cover;
        overflow: hidden;
        .form-wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-46%);
            right: -120px;
            width: 44.06%;
            height: 51.61%;
        }

        &__form {
            opacity: 1;
            margin-top: 10px;
            border-radius: 6px;
            background: #F2F5F7;
            width: 100%;
            height: 100%;
            padding: 20px 60px 50px 60px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .login-form-bc {
                position: absolute;
                background-color: #ffffff;
                top: -10px;
                left: 3.5%;
                width: 93%;
                height: 10px;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                opacity: .3;
            }

            &__title {
                text-align: center;
                color: #2f99f3;
            }

            .el-input {
                height: 16%;

                input {
                    height: 16%;
                }
            }

            .input-icon {
                height: 30px;
                width: 14px;
                margin-left: 2px;
            }

            &__code {
                width: 33%;
                height: 36px;
                float: right;

                &__img {
                    height: 100%;
                    cursor: pointer;
                    vertical-align: middle;
                }
            }
        }

        &__footer {
            height: 40px;
            line-height: 40px;
            position: fixed;
            bottom: 0;
            width: 100%;
            text-align: center;
            color: #333;
            font-family: Arial;
            font-size: 12px;
            letter-spacing: 1px;
        }

        .login-content {
            margin-top: 5%;
            margin-left: 16.14%;
            width: 61%;
            height: 77%;
            background: linear-gradient(30deg, #2D55B3 0%, #2663BF 35%, #4C92D9 100%);
            box-shadow: 8px 14px 36px 0px #C2C7CC;
            border-radius: 10px;
            position: relative;

            .login-page {
                height: 190px;
                display: flex;
                flex-wrap: nowrap;
                font-size: 140px;
                line-height: 180px;
                padding-left: 57px;
                color: #3E8BD9;
                opacity: .3;
            }

            .foot-img {
                width: 45.59%;
                height: 42.26%;
                position: absolute;
                bottom: 0;
                left: 0;
                overflow: hidden;
                border-bottom-left-radius: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .login__form__code__img {
                margin-top: 10px;
            }

            .manager-title {
                padding-left: 57px;

                .title {
                    display: block;
                    font-size: 42px;
                    height: 42px;
                    font-family: Microsoft YaHei;
                    color: #F27C55;
                }

                img {
                    margin-top: 20px;
                }

                .manager-info {
                    margin-top: 29px;
                    font-size: 14;
                    color: #61aaf2;

                    p {
                        height: 24px;
                        line-height: 24px;
                    }
                }
            }

        }

      .el-form-item--small.el-form-item {
        margin-bottom: 4.5%;
      }

      .login__form__title {
            font-size: 28px;
            color: #2E4B73;
            text-align: left;
            white-space: nowrap;
            font-weight: 700;
        }

        .el-form-item {
            //height: 16%;
        }
        .el-form-item /deep/ .el-input__inner {
            //height: 16%;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            outline: 0;
            border-radius: 0;
            border-bottom: 1px solid #869CB3;
            background-color: #F2F5F7;
            font-size: 18px;
            color: #606A73;
        }

        .xkButton {
            position: absolute;
            right: 60px;
            bottom: 0;
            transform: translateY(50%);
            padding: 3.5% 12.5%;
            background: linear-gradient(200deg, #2597FA 2%, #40ACFF 100%);
            border-radius: 6px;
            font-size: 20px;
        }
    }


</style>
